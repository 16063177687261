@import 'src/assets/styles/variables';
.footer {
  padding-top: 60px;
  background-color: $white;
}

.container {
  display: flex;
  max-width: $container-second;
  margin: auto;
  padding-top: 1px;
  padding-bottom: 103px;
  padding-left: 169px;

  @media only screen and (max-width: $screen-xs) {
    flex-wrap: wrap;
    padding-bottom: 60px;
    padding-top: 0;
    padding-left: 0;
  }

  @media only screen and (min-width: $screen-xs) and (max-width: $screen-lg) {
    flex-wrap: wrap;
    padding-bottom: 60px;
    padding-left: 0;
  }

  @media only screen and (min-width: $screen-md) and (max-width: $screen-lg) {
    padding-left: 16px;
  }

  @media screen and (min-width: 1640px) {
    margin-left: 240px;
  }

  @media screen and (min-width: $screen-lg) {
    padding-bottom: 9px;
  }
}

.footerLogoBox {
  flex: 0 0 32.2%;

  @media only screen and (min-width: 1640px) {
    flex: 0 0 28.2%;
  }

  @media only screen and (max-width: $screen-md) {
    flex: unset;
    width: 100%;
    margin: unset;
    padding-left: 16px;
  }
}

.footerLogoBox p {
  font-family: $base-font;
  font-size: $font-size-base;
  font-weight: normal;
  color: $black;
  line-height: 17px;
  padding: 0 0 8px;
}

.footerBox {
  flex: 0 0 19.27%;

  &:last-of-type {
    @media only screen and (max-width: $screen-xs - 54px) {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: $screen-md) {
    flex: unset;
    margin-top: 24px;
  }

  @media only screen and (min-width: $screen-xs) and (max-width: $screen-md) {
    width: 33%;
  }

  @media only screen and (min-width: 1640px) {
    flex: 0 0 17%;
  }
}

.footerBox a {
  color: $black;
  font-weight: normal;
  text-decoration: unset;

  &:hover {
    text-decoration: underline;
  }
}

.footerLogo {
  width: 224px;
  height: 20px;
  margin-bottom: 8px;
}

.footerLinks {
  margin-top: 0;
  list-style: none;

  @media only screen and (max-width: $screen-md) {
    padding-left: 18px;
  }
}

.footerLinksTitle {
  a {
    font-weight: $font-weight-bold;
  }
}

a.footerPhone {
  font-weight: $font-weight-bold;
}

.footerLinks li {
  display: block;
  padding-bottom: 16px;
  color: $black;
}
