@import 'src/assets/styles/variables';

.background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (min-width: 2600px) {
    background-position: center top;
  }
}

// An empty element used to preload other bacground images from url
.nextBackgrounds {
  display: inline;
}

// The animation time must match the value of HotThemeTransitionInterval in constants.ts
.background__overlay {
  &.fade-in {
    animation: fadeIn 0.8s;
  }
  &.fade-out {
    animation: fadeOut 0.8s;
  }
}

@keyframes fadeIn {
  0% {
    background: rgba($blue-darker, 1);
  }
  100% {
    background: rgba($blue-darker, 0);
  }
}

@keyframes fadeOut {
  0% {
    background: rgba($blue-darker, 0);
  }
  100% {
    background: rgba($blue-darker, 1);
  }
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 27.8vh;
  min-height: 293px;
  max-width: $container-second;
  margin: auto;
  padding-top: 72px;
  padding-left: 169px;
  padding-right: 50px;

  @media only screen and (max-width: 320px) {
    padding: 0 16px;
    height: 33.2vh;
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 0 16px;
    height: 343px;
  }

  @media only screen and (min-width: 375px) and (max-width: 600px) {
    padding: 0 16px;
    height: 393px;
  }

  @media only screen and (min-width: 600px) and (max-width: $screen-lg) {
    padding: 0 16px;
    height: 473px;
  }

  @media only screen and (min-width: $screen-lg + 1px) and (max-width: 1920px) {
    height: 500px;
  }

  @media only screen and (min-width: 1640px) {
    padding-left: 408px;
    padding-right: 100px;
  }

  @media only screen and (min-width: 1920px) {
    height: 790px;
    padding-right: 330px;
  }

  @media only screen and (min-width: $higher-resolution) {
    margin: unset;
  }
}

.themePreview__miniHeading {
  color: $white;
  font-weight: $font-weight-bold;
  font-size: 20px;
  line-height: 24px;
  text-shadow: 1px 1px 1px $text-shadow;
  margin-bottom: 8px;

  @media only screen and (max-width: $screen-md) {
    font-size: 14px;
    line-height: 17px;
  }
}

.themePreview__title {
  margin: 0;
  padding-bottom: 8px;
  a {
    color: $white;
    text-shadow: 1px 1px 1px $text-shadow;
  }
}

.themePreview__oneliner {
  max-width: 787px;
  font-family: $base-font;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: $white;

  a {
    color: $white;
  }

  @media only screen and (max-width: $screen-xs) {
    margin: 0 0 23px 0;
  }

  @media only screen and (max-width: $screen-sm) {
    display: none;
  }
}

.progressRow {
  display: flex;
  justify-content: space-between;
  margin-top: 68px;
  max-width: 1300px;

  @media only screen and (max-width: $screen-xs) {
    margin-top: 13px;
  }
}

.progressRow__theme {
  padding: 0 12px;
  flex-basis: 156px;
  cursor: pointer;

  @media only screen and (min-width: 1440px) and (max-width: 1600px) {
    flex-basis: 164px;
  }

  @media only screen and (min-width: $screen-md) and (max-width: $screen-lg) {
    padding: 0 10px;
  }

  @media only screen and (max-width: $screen-md) {
    padding: 0 3.5px;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  &--inactive {
    opacity: 0.4;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 1;
    }
  }

  // Tweak for IE which does not support the media query above, class is added via JS
  &--ie {
    &:hover {
      opacity: 1;
    }
  }
}

.progressRow__themeName {
  padding-top: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: $font-weight-500;
  line-height: 19px;
  color: $white;
  text-shadow: 1px 1px 0 $text-shadow-second;
  @media only screen and (max-width: $screen-sm) {
    font-size: 2vw;
    line-height: 2.375vw;
  }
}

.progressLineHeight svg {
  height: 3px;
  width: 100%;
}

.themePreview__button {
  max-width: 116px;
  margin-top: 16px;
}
