@import 'src/assets/styles/variables';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  position: relative;
  margin: 0 12px;
  padding: 8px;
  border-radius: $card-border-radius;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: $screen-sm) {
    height: 101px;
    width: 148px;
    margin: 0 8px;
  }
}

.cardGradient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(18, 28, 49, 0.6) 0%,
    rgba(38, 58, 99, 0) 100%
  );
}

.cardText {
  font-size: $font-size-h3;
  font-weight: $font-weight-bold;
  line-height: 1;
  text-align: center;
  color: $white;
  text-shadow: 1px 1px 0 $text-shadow-second;
  z-index: 1;

  @media screen and (max-width: $screen-sm) {
    font-size: 18px;
    line-height: 22px;
  }
}
