@import 'src/assets/styles/variables';

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75px;
  min-height: 100%;
  background-color: rgba($blue-darker, 0.8);
  z-index: 1000;
}

.loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loading div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: $link-color;
  animation: loading 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.loading div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.loading div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.loading div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes loading {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

