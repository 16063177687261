@import 'src/assets/styles/variables';

.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  padding-left: 0;
  list-style-type: none;

  @media only screen and (max-width: $screen-sm) {
    display: none;
  }
}

.breadcrumbItem {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  a {
    color: $raisin-black;
    text-decoration: underline;

    &:hover {
      font-weight: normal;
      text-shadow: 1px 0 0 $raisin-black;
    }
  }

  &:before {
    display: inline-block;
    font-family: $icon-font;
    font-size: 10px;
    color: $raisin-black;
    padding-right: 15px;
    padding-left: 12px;
    content: '\e905';
  }

  &:last-of-type span {
    font-weight: $font-weight-bold;
  }
}
