.contentArticle {
  margin-top: 29px;
  font-family: $second-font;
  font-size: $font-size-p;
  line-height: $line-height-p;
  color: $text-color;

  h3,
  h2 {
    font-family: $second-font;
    font-size: 25px;
    line-height: 32px;
    font-weight: 600;
    color: $text-color;
  }

  h1 {
    color: $text-color;
    font-size: 36px;
    font-weight: 700;
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0 0 0 20px;
    list-style-type: disc;
  }

  ul li {
    padding-bottom: 19px;
    font-weight: 700;
  }

  iframe {
    width: 100%;
    max-width: 100%;
    height: 394px;

    @media screen and (max-width: $screen-xs) {
      width: 290px;
      height: 163px;
    }

    @media screen and (min-width: $screen-xs) and (max-width: 658px) {
      width: 490px;
      height: 277px;
    }


    @media screen and (min-width: 658px) and (max-width: 768px) {
      width: 611px;
      height: 344px;
    }
  }

  div > p {
    padding-top: 7px;
    padding-bottom: 20px;

    &:first-child {
      padding-top: 0;
    }
  }

  div:first-child > p.detail-odstavec:first-child:first-letter {
    padding-right: 16px;
    font-family: $base-font;
    font-size: 95px;
    font-weight: bold;
    line-height: 84px;
    float: left;
    color: $blue-lighter;
  }

  a {
    color: $link-color;

    &:hover {
      color: $link-hover-color;
      text-decoration: underline;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  .article-inserted-image img {
    width: 100%;
  }
}

// Tables
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 3px 16px;
  border: 1px solid $black;
  font-family: $base-font;

  td {
    border-color: $black;
    padding: 2px 5px;
    border-left-style: dotted;
    border-right-style: dotted;
    border-width: 1px;
  }
}

.tabulka-seda {
  background-color: $white;
  border-color: $sirocco;

  td {
    border-color: $lily;
    border-top-style: none;
    border-bottom-style: none;
    font-size: 17px;
  }

  tr:nth-child(even) {
    background-color: $white-lilac;
  }
}

em {
  font-size: 17px;
}

// Interview
.question,
.detail-otazka {
  margin-top: 10px;
  line-height: 28px;
  font-weight: 700;
}

// Float images in the text
.contentArticle img[align='left'],
.contentArticle img[style='float: left;'] {
  margin: 0 24px 24px 0;
}

.contentArticle img[align='right'],
.contentArticle img[style='float: right;'] {
  margin: 0 0 24px 24px;
}

// Images descriptions
.article-inserted-image div {
  font-family: $base-font;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  color: $gray-dark;
}

// Textarea
.detail-object.bordered-text {
  padding: 24px;
  padding-left: 38px;
  border: 2px dotted $gull-gray;
  background: $whisper;

  p {
    padding-bottom: 0;
  }
}

.detail-object {
  margin: 40px 0 40px 0;
}

// Infobox content
.d-elm,
.infobox {
  font-family: $base-font;
  float: left;
  margin: 32px 32px 32px 0;
  width: 300px;
  padding: 24px 10px;
  border-top: 2px dotted $botticelli;
  border-bottom: 2px dotted $botticelli;

  ul li {
    margin-bottom: 16px;
    font-weight: $font-weight-base;
  }
}

.d-elm .d-elm-in > p,
.infobox .d-elm-in > p,
.d-elm .d-elm-in,
.infobox .d-elm-in {
  font-size: 16px;
  font-style: italic;
  line-height: 22px;
  margin: 0 0 16px 0;
  padding-top: 0;
  padding-bottom: 0;
}

.d-elm .d-elm-label,
.infobox .d-elm-label {
  color: $grey-chateau;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 16px;
}

.infobox-right {
  float: right;
  margin: 32px 0 32px 32px;
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn-danger {
  color: $white;
  background-color: $valencia;
  border-color: $persian-red;

  &:hover {
    color: $white;
    background-color: $persian-red;
    border-color: $brown;
  }
}
