@import 'src/assets/styles/variables';

.fixedBlockSocial {
  margin-top: 16px;
  font-weight: 700;
  font-size: 12px;
  font-family: $base-font;
  line-height: 18px;
  color: $blue-dark;

  a:hover span {
    color: $blue-lighter;
  }

  span {
    font-size: 26px;
    cursor: pointer;
  }
}

.fixedBlockSocialIcons {
  margin-top: 4px;
}
