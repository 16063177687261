@import 'src/assets/styles/variables';

.notFound {
  max-width: 1440px;
  margin-top: 150px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;

  h1 {
    color: $raisin-black;
  }

  p {
    padding-bottom: 0;
  }

  a {
    color: $link-color;

    &:hover {
      text-decoration: underline;
      color: $link-hover-color;
    }
  }
}
