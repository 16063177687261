@import 'src/assets/styles/variables';

.divider {
  margin: 40px 0 40px 0;
  padding: 24px 0 40px;
  border-top: 2px dotted $gull-gray;
  border-bottom: 2px dotted $gull-gray;
}

.dividerTitle {
  font-family: $base-font;
  margin-bottom: 24px;
  width: 100%;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: $grey-chateau;

  @media screen and (max-width: 1023px){
    padding: 0 10px;
  }

  @media screen and (max-width: 767px){
    font-size: 16px;
    line-height: 24px;
  }
}
