@import 'src/assets/styles/variables';

.button-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight-bold;
  font-size: 12px;
  line-height: 14px;
  padding: 11px 24px;
  background: $link-color;
  color: $white;
  border-radius: $button-border-radius;
  border: 0;

  &:after {
    content: '\e906';
    display: inline-block;
    margin-left: 7px;
    font-family: $icon-font;
    font-size: 12px;
    font-weight: 400;
    color: $white;
  }

  &:hover {
    background: $link-hover-color;
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }
}

.button-link {
  background: transparent;
  border: transparent;
  padding: 0;
  outline: none;
}
