@import 'src/assets/styles/variables';

.container {
  max-width: 700px;
  margin: auto;
  padding-top: 68px;
  padding-bottom: 40px;
  position: relative;

  @media screen and (max-width: $screen-md) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @media screen and (max-width: $screen-sm) {
    padding-top: 110px;
    padding-bottom: 4px;
  }
}

.containerArticle {
  max-width: 1440px;
  margin: auto;
}

h1.articleTitle {
  margin-bottom: 15px;
  font-family: $second-font;
  font-size: $font-size-h2;
  line-height: 48px;
  font-weight: $font-weight-base;
  color: $blue-lighter;
  text-shadow: none;

  @media screen and (max-width: $screen-sm) {
    margin-bottom: 10px;
    font-size: 28px;
    line-height: 37px;
  }
}

.content {
  margin-top: 29px;
  font-family: $second-font;
  font-size: $font-size-p;
  line-height: $line-height-p;
  color: $text-color;

  h3,
  h2 {
    font-family: $second-font;
    font-size: 25px;
    line-height: 32px;
    font-weight: 600;
    color: $text-color;
  }

  ul li {
    padding-bottom: 19px;
    font-weight: 700;
  }

  iframe {
    @media screen and (max-width: $screen-xs) {
      max-width: 290px;
      height: 163px;
    }
  }

  div > p {
    padding-top: 15px;
    padding-bottom: 12px;

    &:first-child {
      padding-top: 0;
    }
  }

  div > p:first-child:first-letter {
    padding-right: 16px;
    font-family: $base-font;
    font-size: 95px;
    font-weight: bold;
    line-height: 84px;
    float: left;
    color: $blue-lighter;
  }

  img {
    width: 100%;
  }
}

// displaying of logout
.articleHidden {
  max-height: 530px;
  overflow: hidden;
}

// Fixed block
.fixedBlock {
  display: none;
  white-space: pre-line;
  @media screen and (min-width: 1238px) {
    display: block;
    max-width: 168px;
    position: absolute;
    top: 36px;
    left: -254px;
    font-size: 13px;
    line-height: 16px;
  }
}
.fixedBlockTitle {
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  a {
    padding-left: 13px;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: $blue-lighter;
  }

  &:before {
    display: inline-block;
    position: absolute;
    content: '\e901';
    top: 1.5px;
    left: 0;
    font-family: $icon-font;
    font-size: 9px;
    color: $blue-lighter;
    transition: transform 0.3s ease-out;
  }

  &:hover:before {
    transform: translateX(-4px);
  }
}

.fixedBlockSocial {
  margin-top: 16px;
  font-weight: 700;
  font-size: 12px;
  font-family: $base-font;
  line-height: 18px;
  color: $blue-dark;

  a:hover span {
    color: $blue-lighter;
  }

  span {
    font-size: 26px;
    cursor: pointer;
  }
}

.fixedBlockSocialIcons {
  margin-top: 4px;
}

//TODO: add to component, see ArticleCard
.subtitlePublished,
.subtitle {
  display: block;
  font-size: 12px;
  font-weight: $font-weight-base;
  line-height: 14px;
  color: $gray-darker;
}
.subtitle {
  display: flex;
  align-items: center;
  float: left;
}

.subtitlePublished {
  text-align: right;
}
//

.dot {
  display: inline-block;
  margin-right: 6px;
  margin-left: 6px;
  height: 2px;
  width: 2px;
  background-color: $gray-darker;
  border-radius: 50%;
}

.square {
  display: inline-block;
  height: 11px;
  width: 11px;
  background-color: $blue-lighter;
}

.sticky {
  position: fixed;
  top: 141px;
  transition: visibility 500ms ease, opacity 500ms ease;
  &.sticky--hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.contentFooter {
  display: none;

  @media screen and (max-width: 1238px) {
    margin-top: 35px;
    display: block;
  }
}

// Article detail
.containerTitle {
  max-width: 700px;
  margin: auto;

  @media screen and (max-width: $screen-lg - 1px) {
    margin: unset;
  }

  @media screen and (min-width: 992px) and (max-width: $screen-lg - 1px) {
    margin-left: 32px;
  }
}

.titleContinue {
  max-width: 700px;
  margin: auto;
  padding-bottom: 16px;
  color: $raisin-black;

  @media screen and (max-width: $screen-md) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  margin-left: 16px;
  margin-right: 16px;
}

.sliderWrap {
  margin-bottom: 50px;
}
