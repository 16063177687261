@import 'src/assets/styles/variables';

.container {
  background-color: $blue-darker;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow: hidden;
}

.closeButton {
  position: absolute;
  height: 103px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  color: $white;
  font-size: 40px;
  line-height: 19px;
  z-index: 99;

  span {
    margin-right: 112px;
    cursor: pointer;

    @media only screen and (max-width: $screen-md) {
      margin-right: 7px;
    }
  }

  @media only screen and (max-width: $screen-md) {
    max-height: 50px;
  }
}
