//
// Variables
// --------------------------------------------------

//== Colors
$red: #d6392e;
$gray: #e5e5e5;
$gray-very-light: #e7e7e7;
$gray-light: #c4c4c4;
$gray-dark: #ccd6db;
$gray-very-dark: #3e3e3e;
$gray-very-dark-icon: #484848;
$gray-darker: #979797;
$whisper: #eee;
$gull-gray: #95aab3;
$white: #fff;
$blue-lighter: #263a63;
$blue-dark: #0b121f;
$blue-darker: #0a111e;
$black: #000;
$raisin-black: #231f20;
$sirocco: #6d5f66;
$lily: #b6afb2;
$white-lilac: #eae9eb;
$botticelli: #95aab3;
$grey-chateau: #97a2a7;
$valencia: #d9534f;
$persian-red: #d43f3a;
$brown: #ac2925;

$text-shadow: rgba(0, 0, 0, 0.4);
$text-shadow-second: rgba(0, 0, 0, 0.25);

//** Background color for `<header>`.
$header-bg: #fbfbfb;
//** Background color for `<body>`.
$body-bg: $gray;
//** Global text color on `<body>`.
$text-color: #444;
//** Global textual link color.
$link-color: #e82020;
//** Link hover color set via `darken()` function.
$link-hover-color: #bf1818;

//== Typography
$base-font: 'Fira Sans';
$second-font: 'PT Serif';
$twitter-font: 'Helvetica Neue';
$icon-font: 'icons';

$font-size-base: 14px;
$line-height-base: 17px;

$font-weight-base: normal;
$font-weight-bold: bold;
$font-weight-500: 500;

$font-style-base: normal;

$font-size-h1: 48px;
$font-size-h2: 40px;
$font-size-h3: 24px;
$font-size-h4: 14px;
$font-size-p: 22px;

$line-height-h1: 58px;
$line-height-h2: 40px;
$line-height-h3: 28px;
$line-height-h4: 17px;
$line-height-p: 28px;

//== Buttons
$btn-primary-color: $white;
$btn-primary-bg: $link-color;
$btn-primary-border: $link-color;

//## Define the breakpoints.
// Extra small screen / phone
$screen-xs: 576px;
// Small screen / tablet
$screen-sm: 768px;
// Medium screen / desktop
$screen-md: 992px;
// Large screen / wide desktop
$screen-lg: 1200px;

$resize-height-max: 730px;
$resize-height-min: 600px;

//== Borders
// Border radius used by theme and article cards and hot theme image
$card-border-radius: 8px;
$button-border-radius: 27px;

//== Container sizes
$container: 1256px;
$container-second: 1440px;
$higher-resolution: 2200px;
