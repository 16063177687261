@import 'src/assets/styles/variables';

.arrow {
  color: $raisin-black;
  padding-left: 0;
  padding-right: 0;

  // Left arrow
  &:first-child {
    background-image: linear-gradient(90deg, $white 0%, rgba(255, 255, 255, 0.776042) 35.42%, rgba(255, 255, 255, 0) 100%);
  }
  // Right arrow
  &:last-child {
    background-image: linear-gradient(270deg, $white 0%, rgba(255, 255, 255, 0.776042) 35.42%, rgba(255, 255, 255, 0) 100%);
  }
}
