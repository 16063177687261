@import 'src/assets/styles/variables';

.slider {
  position: relative;
  transition: margin-left 1s;
}

.arrow {
  transition: visibility 1000ms ease, opacity 250ms ease;
  cursor: pointer;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 70px;
  // Left arrow
  &:first-child {
    margin-left: -70px;
    left: 70px;
  }
  // Right arrow
  &:last-child {
    margin-right: -70px;
    right: 70px;
    justify-content: flex-end;
  }
  @media only screen and (max-width: $screen-xs) {
    visibility: hidden;
  }
}

.arrow--disabled {
  visibility: hidden;
  opacity: 0;
}

.arrow__inner {
  padding-left: 20px;
  padding-right: 20px;
}

.arrow__overlay {
  @media only screen and (min-width: $screen-xs + 1) {
    position: absolute;
    width: 100px;
    height: 200px;
    z-index: 999;
    visibility: hidden;
  }
}

.arrow__overlayLeft {
  left: 0;
  visibility: visible;
}

.arrow__overlayRight {
  right: 0;
  visibility: visible;
}

.menu {
  display: flex;
  align-items: center;
}

.item:focus {
  outline: none;
}
