@import 'src/assets/styles/variables';

.articleMeta {
  margin: 32px auto 32px;
  width: 100%;
  max-width: 768px;
  font-size: 14px;
  line-height: 24px;
  color: $text-color;
}

.author {
  display: block;
  margin-bottom: 16px;
}

.authorsWrapper {
  position: relative;
  padding-left: 64px !important;
  display: block;
  list-style-type: none;
  margin: 0;
  font-family: $second-font;

  li {
    display: inline;
    margin-right: 5px;
    font-size: 16px;
    font-weight: 700;
  }

  &:before {
    font-family: $icon-font;
    font-size: 30px;
    content: "\e912";
    position: absolute;
    display: block;
    top: 12px;
    left: 10px;
    width: 34px;
    height: 34px;
    color: $link-color;
  }
}

.publishDate {
  padding-left: 64px;
  font-family: $second-font;
  font-weight: 700;
  color: #97a2a7;

  span {
    color: $link-color;
  }
}

.tags {
  display: inline;

  span {
    font-family: $base-font;
    font-size: 12px;
    font-weight: 300;
    color: $blue-lighter;
    border: 1px solid $gray-light;
    border-radius: 1px;
    margin-bottom: 4px;
    margin-right: 5px;
    display: inline-block;
    height: 24px;
    text-transform: uppercase;
    padding: 0 8px;
  }
}

.perex {
  margin: 0 auto 32px;
  max-width: 768px;
  font-family: $second-font;
  font-weight: 700;
  font-size: 17px;
  line-height: 28px;
  color: $text-color;

  @media screen and (max-width: 767px) {
    font-size: 15px;
    line-height: 24px;
  }
}
