@import 'src/assets/styles/variables';

.preview {
  position: relative;
}

.imageContainer {
  text-align: center;
}

.image {
  cursor: pointer;
  margin: auto;
}

.galleryComponent {
  position: absolute;
  font-family: $base-font;
  bottom: 24px;
  left: 51px;
  right: 31px;
  color: $white;
  text-align: center;

  @media only screen and (max-width: 425px) {
    text-align: left;
  }
}

.type {
  display: block;
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
  text-shadow: 0 0 8px $black;
  color: $white;
}

.title {
  display: block;
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 16px;
  color: $white;
  text-shadow: 0 0 8px $black;

  @media only screen and (max-width: 425px) {
    max-height: 48px;
    overflow: hidden;
    padding-right: 64px;
  }
}

.mediaButton {
  width: 48px;
  height: 48px;
  padding: 0;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.32);
  border: none;
  border-radius: 50%;
  background-color: $link-color;
  text-align: center;
  cursor: pointer;

  &:hover {
    background-color: $link-hover-color;
  }

  span {
    font-size: 27px;

    @media only screen and (max-width: 425px) {
      font-size: 20px;
    }
  }

  @media only screen and (max-width: 425px) {
    position: absolute;
    right: 0;
    bottom: 0;
  }

  @media only screen and (max-width: 425px) {
    width: 36px;
    height: 36px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.32);
  }
}
