@import '../../assets/styles/variables';

@font-face {
  font-family: 'icons';
  src: url('../../assets/fonts/icons/icons.ttf?q2d3kk') format('truetype'),
    url('../../assets/fonts/icons/icons.woff?q2d3kk') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-author:before {
  content: "\e912";
}
.icon-image:before {
  content: '\e911';
}
.icon-arrow-right-header:before {
  content: "\e910";
}
.icon-tick:before {
  content: '\e90e';
}
.icon-arrow-left:before {
  content: '\e900';
}
.icon-arrow-left-9:before {
  content: '\e901';
}
.icon-arrow-left-13:before {
  content: '\e902';
}
.icon-arrow-right:before {
  content: '\e903';
}
.icon-arrow-right-8:before {
  content: '\e904';
}
.icon-arrow-right-9:before {
  content: '\e905';
}
.icon-arrow-button:before {
  content: '\e906';
}
.icon-arrow-right-22:before {
  content: '\e907';
}
.icon-bars:before {
  content: '\e908';
}
.icon-close:before {
  content: '\e909';
}
.icon-face:before {
  content: '\e90a';
}
.icon-facebook:before {
  content: '\e90b';
}
.icon-fe-link:before {
  content: '\e90c';
}
.icon-twitter:before {
  content: '\e90d';
}

.icon-logout:before {
  content: '\e90f';
}

// Colors
.white {
  color: $white;
}
.blue-lighter {
  color: $blue-lighter;
}
.blue-darker {
  color: $blue-darker;
}
.blue-dark {
  color: $blue-dark;
}
.raisin-black {
  color: $raisin-black;
}
.red {
  color: $link-color;
}
