@import 'src/assets/styles/variables';

.articleInfographics {
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.infographics--zoom {
  position: absolute;
  top: 20px;
  left: 10px;
  max-width: 100wv;
  height: auto;
}

.zoomInfographics {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  overflow-y: scroll;
  background-color: $white;
  text-align: center;

  .closeButton {
    position: fixed;
    top: 32px;
    right: 32px;
    z-index: 99999999999;

    span {
      font-size: 38px;
      cursor: pointer;
    }

    &:hover {
      span {
        color: $link-hover-color;
      }
    }

    @media only screen and (max-width: 1023px) {
      top: 8px;
      right: 25px;
    }
  }

  .closeButtonWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $white;
    opacity: 0.7;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.32);

    @media only screen and (max-width: 1023px) {
      width: 36px;
      height: 36px;
    }

    &:hover {
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.32);
    }
  }

  img {
    max-width: 90%;
    margin: 0 auto;
  }
}
