@import 'src/assets/styles/variables';

$font-size-header: 14px;
$font-size-header-mobile: 18px;
$line-height: 22px;

.homepageContainer.header {
  max-width: 1440px;
  padding: 20px 50px 21px 169px;

  @media only screen and (max-width: $screen-lg) {
    padding-left: 10px;
    padding-right: 10px;
  }

  @media only screen and (min-width: 1640px) {
    padding-left: 408px;
    padding-right: 100px;
  }

  @media only screen and (min-width: 1920px) {
    padding-right: 330px;
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  z-index: 1000;
  background-color: $header-bg;
  padding: 21px 31px;

  @media only screen and (max-width: $screen-sm) {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    padding: 10px 11px;
  }
}

.headerLogo {
  display: inline-block;
  margin-bottom: 2px;
  width: 116px;
  height: 21px;
  background: 50% no-repeat url(../../assets/images/kontext-logo.svg);
}

.headerLogoLink {
  padding-top: 2px;
}

.headerLogoIcon {
  padding-top: 5px;

  span {
    font-size: 14px;
    padding-right: 9px;
    padding-left: 11px;
  }
}

.login {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  align-items: center;
  text-decoration: none;
  margin-left: auto;
}

.user {
  display: flex;
  align-items: center;
  justify-content: center;

  span.icon-face {
    text-shadow: none;
  }
}

.logoutButton {
  cursor: pointer;
}

.userName {
  margin-left: 8px;
  margin-right: 8px;
  font-size: $font-size-base;
  line-height: $line-height-base;

  @media only screen and (max-width: $screen-md) {
    font-size: $font-size-header-mobile;
    line-height: $line-height;
  }
}

.toggleButton {
  display: none;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  align-items: center;
  text-decoration: none;
  margin-left: auto;
  color: $blue-dark;

  @media only screen and (max-width: $screen-md) {
    display: inline-block;
    margin-top: 5px;
  }
}

.showBreadcrumb .toggleButton {
  position: relative;

  :after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: -50px;
    width: 50px;
    background: linear-gradient(270deg, #fbfbfb 80.16%, rgba(251, 251, 251, 0) 100%);
  }
}

.toggleIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    cursor: pointer;
  }
}

.toggleText {
  margin-left: 8px;
  font-family: $base-font;
  font-style: normal;
  font-weight: $font-weight-500;
  font-size: $font-size-header;
  line-height: 17px;
  letter-spacing: 2px;
  color: $blue-lighter;
}

.headerNavigation {
  margin-left: auto;

  ul {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  li {
    overflow: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    &:not(:last-of-type) {
      margin-right: 30px;
    }

    &:hover {
      a {
        font-weight: normal;
        text-shadow: 1px 0 0 $raisin-black;
      }
      a span:first-of-type {
        text-shadow: none;
      }
    }

    @media only screen and (max-width: $screen-md) {
      text-align: center;
      margin: 0;
    }
  }

  li:first-of-type:hover a {
    text-shadow: 1px 0 0 $red;
  }

  li.active a {
    color: $red;
    text-transform: uppercase;
  }

  li a {
    font-size: $font-size-header;
    line-height: 17px;
    color: $raisin-black;
    text-decoration: unset;
  }

  @media only screen and (max-width: $screen-md) {
    display: none;
  }
}

.headerNavigationOpen {
  @media only screen and (max-width: $screen-md) {
    position: fixed;
    display: flex;
    left: 0;
    right: 0;
    top: 72px;
    bottom: 0;
    background-color: rgba($white, 0.98);
    align-items: center;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 0;
    justify-content: center;

    ul {
      display: block;
    }

    li {
      width: 280px;
      padding-bottom: 25px;
      padding-top: 25px;
      padding-left: 0;

      &:not(:last-of-type) {
        margin-right: 0;
        border-bottom: 1px solid rgba($text-color, 0.2);
      }
    }

    li a {
      font-size: $font-size-header-mobile;
      line-height: $line-height;
    }
  }
}

.showBreadcrumb .headerNavigationOpen {
  @media only screen and (max-width: $screen-sm) {
    top: 103px;
  }
}

// Header for mobile devices
.headerPhone {
  display: block;
  padding: 12px 7px 10px 7px;

  .logoMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 23px;
  }

  .headerLogo {
    margin-bottom: 0;
  }

  .headerPhoneContent {
    display: flex;

    .link {
      display: flex;
      align-items: center;
      position: relative;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      font-weight: 600;
      font-size: 16px;
      line-height: 18px;
      color: $black;
      padding-left: 26px;

      &:before {
        position: absolute;
        content: '\e902';
        font-family: $icon-font;
        font-size: 13px;
        top: 7px;
        left: 0;
        transition: transform 0.3s ease-out;
        font-weight: normal;
      }
    }
  }
  .headerPhoneContentBottom {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .toggleButton {
    position: relative;

    :after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: -50px;
      width: 50px;
      background: linear-gradient(270deg, #fbfbfb 80.16%, rgba(251, 251, 251, 0) 100%);
    }
  }
}
