@import 'src/assets/styles/variables';

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .flexItem {
    // TODO: adjust baseline
    flex: 1;
    max-width: 627px;
    @media screen and (max-width: $screen-sm) {
      flex: unset;
    }
  }
}

.image {
  max-width: 96%;
  height: auto;
  border-radius: $card-border-radius;

  @media screen and (max-width: $screen-xs) {
    max-width: 100%;
  }
}

.textCol {
  padding-left: 7px;
  padding-right: 16px;
  padding-bottom: 50px;

  @media screen and (max-width: $screen-sm) {
    padding-left: 0;
    padding-bottom: 0;
  }

  button {
    @media screen and (max-width: $screen-xs) {
      margin: auto;
    }
  }
}

.title {
  margin-top: 0;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: $font-size-h1;
  line-height: 58px;
  color: $white;

  @media screen and (max-width: $screen-lg) {
    margin-top: 25px;
    margin-bottom: 8px;
    font-size: 28px;
    line-height: 34px;
  }
}

.perex {
  margin-bottom: 32px;
  padding-right: 150px;
  font-size: 18px;
  line-height: 32px;
  color: $white;

  @media screen and (max-width: $screen-xs) {
    margin-bottom: 17px;
    padding: 0;
    font-size: 16px;
    line-height: 28px;
  }
}
