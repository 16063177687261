@import 'src/assets/styles/variables';

.background {
  background-color: $blue-darker;
  padding-bottom: 32px;

  @media screen and (max-width: $screen-lg) {
    padding-bottom: 0;
  }
}

.container {
  max-width: 1440px;
  margin: auto;
  padding-left: 132px;

  @media screen and (max-width: $screen-lg) {
    padding-left: 0;
  }

  @media screen and (max-width: $screen-lg) {
    margin-top: 16px;
    margin-right: 16px;
    margin-left: 16px;
  }
}

.containerSliders {
  max-width: 1440px;
  margin: auto;
  padding-left: 121px;

  @media screen and (max-width: $screen-lg) {
    padding-left: 0;
    margin-left: 3px;
  }

  @media screen and (max-width: $screen-xs) {
    margin-left: 3px;
  }
}

.containerFullWidth {
  @media screen and (max-width: 1640px) and (min-width: 1441px) {
    max-width: 1440px;
    margin-left: 27px;
  }

  @media screen and (min-width: 1640px) {
    max-width: 1319px;
    margin-left: 240px;
  }
}

.intro {
  padding-bottom: 42px;

  @media screen and (max-width: $screen-lg) {
    padding-bottom: 7px;
  }
}

.homeBody {
  margin-top: 31px;

  @media screen and (max-width: $screen-lg) {
    margin-top: 33px;
  }
}

.row {
  padding-bottom: 60px;

  @media screen and (max-width: $screen-lg) {
    padding-bottom: 47px;
  }
}

.row__heading {
  padding: 0 0 24px 169px;
  color: $white;
  font-family: $base-font;
  font-style: $font-style-base;
  font-weight: $font-weight-bold;
  font-size: 16px;
  line-height: 19px;

  @media screen and (max-width: $screen-lg) {
    padding: 0 0 16px 16px;
  }

  @media screen and (max-width: $screen-xs) {
    font-size: 14px;
    line-height: 17px;
  }
}

.row__featuredTheme {
  margin-top: 4px;

  @media screen and (max-width: $screen-lg) {
    margin-top: 0;
  }
}

.paddingBottom32 {
  padding-bottom: 32px;

  @media screen and (max-width: $screen-lg) {
    padding-bottom: 0;
  }
}

.marginBottom40 {
  margin-bottom: 40px;

  @media screen and (max-width: $screen-sm) {
    margin-bottom: 32px;
  }
}
