@import 'src/assets/styles/variables';

.arrow {
  color: $white;
  // Left arrow
  &:first-child {
    background-image: linear-gradient(
      -90deg,
      rgba(10, 17, 30, 0) 0%,
      #0a111e 97.72%
    );
  }
  // Right arrow
  &:last-child {
    background-image: linear-gradient(
      90deg,
      rgba(10, 17, 30, 0) 0%,
      #0a111e 97.72%
    );
  }
}
