@import 'src/assets/styles/variables';

// Article images have different ratio and resolution
// therefore fixed width of .card and height of .imageContainer must be used
.card {
  border: 1px solid $gray-dark;
  background-color: $white;
  border-radius: $card-border-radius;
  line-height: 1.429;

  @media only screen and (min-width: $screen-lg) {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0;
  }
}

.imageContainer {
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: 56.5%;
}

.image {
  width: 100%;
  border-top-left-radius: $card-border-radius - 1px;
  border-top-right-radius: $card-border-radius - 1px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
}

.title {
  text-shadow: 1px 0 0 $blue-lighter;
  font-size: 129%;
  font-weight: $font-weight-base;
  line-height: inherit;
  margin-top: 6px;
  margin-bottom: 22px;
  position: relative;
  height: 72px;
  overflow: hidden;

  @media only screen and (max-height: 681px) {
    height: 58px;
  }

  @media only screen and (max-width: 1100px) {
    height: 65px;
    font-size: 16px;
    line-height: 21px;
  }

  @media only screen and (max-width: 819px) {
    height: 72px;
    font-size: 18px;
    line-height: 24px;
  }

  @media only screen and (max-width: 660px) {
    height: 65px;
    font-size: 16px;
    line-height: 21px;
  }

  @media only screen and (max-width: 581px) {
    height: 72px;
    font-size: 18px;
    line-height: 24px;
  }

  @media only screen and (max-width: 350px) {
    font-size: 16px;
    line-height: 21px;
  }

  a {
    color: $blue-lighter;

    &:hover {
      color: $gray-darker;
      font-weight: normal;
      text-shadow: none;
    }
  }

  @media only screen and (max-width: $screen-xs) {
    margin-bottom: 24px;
    height: unset;
  }
}

//TODO: add to component, see Article
.subtitlePublished,
.subtitle {
  display: block;
  font-size: 12px;
  font-weight: $font-weight-base;
  line-height: 14px;
  color: $gray-darker;
}
.subtitle {
  display: flex;
  align-items: center;
  float: left;
}

.subtitlePublished {
  text-align: right;
}

.subtitlePublished {
  text-align: right;
}

.body {
  padding: 17px 25px 24px 28px;
  background-color: $white;
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

.bodyResize {
  @media only screen and (max-height: $resize-height-max) and (min-height: $resize-height-min) and (min-width: $screen-lg) {
    padding: 4% 6% 5.6% 6.5%;
  }

  @media only screen and (max-height: $resize-height-min) and (min-width: $screen-lg) {
    padding: 13px 17px 13px 17px;
  }

  @media only screen and (min-width: $screen-xs) and (max-width: $screen-lg) {
    padding: 12px 10px 16px 10px;
  }

  @media only screen and (max-width: $screen-xs) {
    padding: 17px 17px 24px 16px;
  }
}

.bodyButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardSecondVariant {
  border: none;

  .title {
    max-height: 72px;
    height: unset;
    margin-bottom: 15px;
    min-height: 0;
    padding-bottom: 0;
  }

  .perex {
    color: $black;
    white-space: pre-line;
  }

  .imageContainer {
    border-radius: 8px;
  }

  .body {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;

    @media only screen and (min-width: $screen-xs) and (max-width: $screen-lg) {
      padding-top: 10px;
    }

    @media only screen and (max-width: $screen-xs) {
      padding-top: 15px;
    }
  }

  .bodyText {
    @media only screen and (min-width: $screen-lg) {
      height: 68px;
    }
  }

  a:hover h3 {
    color: $gray-darker;
    font-weight: normal;
    text-shadow: none;
  }
}

.dot {
  display: inline-block;
  margin-right: 6px;
  margin-left: 6px;
  height: 2px;
  width: 2px;
  background-color: $gray-darker;
  border-radius: 50%;
}
