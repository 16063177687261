@import 'src/assets/styles/variables';
.background {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

.gradient {
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
}

.container {
  max-width: $container-second;
  margin: auto;
  padding-top: 20px;
  padding-left: 87px;

  @media only screen and (max-width: $screen-sm) {
    padding-top: 110px;
  }

  @media only screen and (max-width: $screen-sm - 1px) {
    margin-left: 18px;
    margin-right: 18px;
    padding-left: 0;
  }

  @media only screen and (width: $screen-sm) {
    padding-left: 61px;
  }
}

.themeSlider {
  margin-left: -15px;
  margin-bottom: 29px;
}

.title {
  position: relative;
  display: inline-block;
  font-family: $second-font;
  font-size: $font-size-h1;
  font-weight: $font-weight-bold;
  margin-top: 30px;
  margin-bottom: 36px;
  line-height: 50px;

  &:after {
    display: block;
    width: 100%;
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: -9px;
    height: 2px;
    border-radius: $card-border-radius - 4px;
    background-color: $link-color;

    @media only screen and (max-width: $screen-sm - 1px) {
      bottom: 6px;
    }
  }

  @media only screen and (max-width: $screen-sm  - 1px) {
    font-size: $line-height-p - 1px;
    margin-bottom: 25px;
  }

  @media only screen and (min-width: $screen-sm) and (max-width: $screen-md) {
    margin-top: 24px;
  }
}

.perex {
  max-width: 676px;
  margin-bottom: 34px;
  font-family: $base-font;
  font-size: 16px;
  line-height: 24px;
  color: $blue-lighter;
  white-space: pre-line;

  @media only screen and (max-width: $screen-sm - 1px) {
    margin-bottom: 10px;
    margin-left: 0;
    font-size: 14px;
  }

  @media only screen and (min-width: $screen-sm - 1px) and (max-width: $screen-md) {
    margin-bottom: 5px;
  }
}

.link {
  position: relative;
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: $blue-lighter;
  padding-left: 17px;

  &:before {
    position: absolute;
    content: '\e902';
    font-family: $icon-font;
    font-size: 13px;
    top: 0.5px;
    left: 0;
    transition: transform 0.3s ease-out;
    font-weight: normal;
  }

  &:hover:before {
    transform: translateX(-4px);
  }
}
