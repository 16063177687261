@import 'src/assets/styles/variables';

.gallery {
  position: relative;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-color: rgba(64, 64, 64, 0.96);
  font-family: $base-font;
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  max-height: 100%;

  img {
    flex: 0 1 auto;
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    min-height: 0;
    object-fit: contain;
    vertical-align: middle;
  }
}

.closeButton {
  position: fixed;
  top: 32px;
  right: 32px;
  z-index: 99999999999;

  span {
    font-size: 38px;
    cursor: pointer;
  }

  &:hover {
    span {
      color: $link-hover-color;
    }
  }

  @media only screen and (max-width: 1023px) {
    top: 8px;
    right: 8px;
  }
}

.closeButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: $white;
  opacity: 0.7;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.32);

  @media only screen and (max-width: 1023px) {
    width: 36px;
    height: 36px;
  }

  &:hover {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.32);
  }
}

.arrowsBox {
  position: fixed;
  top: 50%;
  width: 100%;
  height: 26px;
  transform: translateY(-50%);
  z-index: 999;

  .arrowWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $white;
    cursor: pointer;

    &:hover {
      span {
        color: $link-hover-color;
      }
    }

    @media only screen and (max-width: 1023px) {
      width: 36px;
      height: 36px;
    }
  }
}

.galleryPager {
  font-family: $base-font;
  color: $gray-light;
  flex: 0 0 auto;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
}

.arrow {
  display: block;
  position: fixed;
  top: calc(50% - 24px);
  left: 32px;
  opacity: 0.7;

  &.arrow--right {
    left: auto;
    right: 32px;

    @media only screen and (max-width: 1023px) {
      left: auto;
      right: 8px;
    }
  }
  @media only screen and (max-width: 1023px) {
    top: calc(50% - 18px);
    left: 8px;
  }
}

.galleryItemDescr {
  flex: 0 0 auto;
  padding: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: $gray-light;
  background-color: rgba(0, 0, 0, 0.7);

  > div {
    margin: auto;

    @media only screen and (max-width: 1023px) {
      width: 100%;
      max-width: 100%;
    }
  }

  .author {
    display: inline;
    margin-left: 7px;
    font-size: 14px;
    white-space: nowrap;

    @media only screen and (max-width: 480px) {
      font-size: 10px;
    }
  }

  @media only screen and (max-width: 1023px) {
    padding: 10px 50px;
  }

  @media only screen and (max-width: 480px) {
    font-size: 12px;
    line-height: 16px;
  }
}
