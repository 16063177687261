@import 'src/assets/styles/variables';

.arrowsBox {
  position: fixed;
  top: 50%;
  width: 100%;
  height: 26px;
  transform: translateY(-50%);
  z-index: 999;
}

.arrow {
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  &.arrow--left {
    left: 30px;

    @media only screen and (max-width: $screen-md) {
      left: -12px;
    }
  }
  &.arrow--right {
    right: 30px;

    @media only screen and (max-width: $screen-md) {
      right: -12px;
    }
  }
}

.menu {
  display: flex;
  align-items: center;
}

.slider {
  width: 100%;
  overflow: hidden;

  @media only screen and (max-height: 415px) {
    position: absolute;
    top: 0;
    height: 100%;
    max-height: calc(100vh - 30px);
  }

  @media only screen and (max-width: 320px), (max-height: 480px) {
    position: absolute;
    top: 0;
    height: 100%;
    max-height: calc(100vh - 30px);
  }
}

.sliderContent {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;

  @media only screen and (max-height: 415px) {
    height: 100%;
  }

  @media only screen and (max-width: 320px), (max-height: 480px) {
    height: 100%;
  }

  > div {
    max-height: calc(100% - 5px);
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.dots {
  position: fixed;
  top: calc(50% + 260px);
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 15px;
  padding-top: 5px;
  background-color: $blue-darker;

  @media only screen and (max-width: $screen-sm), (max-height: 640px) {
    top: calc(50% + 190px);
  }

  @media only screen and (max-width: 400px), (max-height: 450px) {
    top: auto;
    bottom: 0;
  }

  @media only screen and (max-height: 450px) {
    top: auto;
    bottom: 0;
  }
}

.dots__inner {
  width: 94px;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: $screen-xs) {
    width: 62px;
  }
}

.dot {
  border-radius: 50%;
  background-color: $gray-light;
  width: 6px;
  height: 6px;
  &:hover {
    background-color: $link-color;
    cursor: pointer;
  }
}

.dot--active {
  background-color: $link-color;
  width: 10px;
  height: 10px;
}

.slide {
  text-align: center;
  width: 100%;
}
