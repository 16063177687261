@import 'src/assets/styles/variables';

.container {
  position: relative;
  max-width: 700px;
  margin-top: 15px;
  border-top: 1px solid $gray-light;
}

.title {
  line-height: 33px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 40px;
  padding-top: 40px;
}

.subtitle {
  font-size: 21px;
  line-height: 25px;
  color: $text-color;
  margin: 0;
}

.box {
  padding: 16px 27px;
  border: 1px solid $gray-very-light;
  box-sizing: border-box;

  a {
    margin: auto;
  }
}

.boxFirst {
  min-width: 324px;
  margin-right: 26px;
  float: left;

  @media only screen and (max-width: $screen-md) {
    min-width: 100%;
    margin-top: 15px;
  }
}

.boxSecond {
  min-width: 344px;
  text-align: center;
  float: right;

  @media only screen and (max-width: $screen-md) {
    min-width: 100%;
    margin-top: 15px;
  }
}

.list {
  padding-left: 26px;
  font-size: $font-size-h4;
  line-height: $line-height-h4;
  color: $gray-very-dark;
  list-style-type: none;

  li:first-of-type {
    font-weight: $font-weight-bold;
  }

  li {
    position: relative;
    padding-bottom: 9px;
    &:before {
      content: '\e90e';
      position: absolute;
      font-family: $icon-font;
      font-weight: $font-weight-base;
      left: -26px;
      color: $gray-very-dark-icon;
    }
  }
}

.priceTitle {
  font-weight: bold;
  font-size: $font-size-h3;
  line-height: 29px;
  color: $text-color;
}

.shadow {
  position: absolute;
  height: 182px;
  top: -185px;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(180deg);
}

.textClear {
  clear: both;
}

.bodyButton {
  display: flex;
  align-items: center;
  margin-top: 24px;
}
