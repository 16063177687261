@import 'assets/styles/variables';
@import 'assets/styles/fonts';
@import 'assets/styles/articleContent';

// Disable bounce on ios devices
// -------------------------
html {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

body {
  font-family: $base-font;
  font-style: $font-style-base;
  font-weight: $font-weight-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  margin: 0;
  width: 100vw;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

// Headings
// -------------------------
h1,
.h1 {
  font-family: $base-font;
  font-style: $font-style-base;
  font-weight: $font-weight-bold;
  font-size: $font-size-h1;
  line-height: $line-height-h1;
  color: $white;

  @media only screen and (max-width: $screen-lg) {
    font-size: $font-size-h3;
    line-height: 29px;
  }
}

h2,
.h2 {
  font-family: $base-font;
  font-style: $font-style-base;
  font-weight: $font-weight-base;
  font-size: $font-size-h2;
  line-height: $line-height-h2;
  color: $blue-lighter;
}

h3,
.h3 {
  font-family: $base-font;
  font-style: $font-style-base;
  font-weight: $font-weight-bold;
  font-size: $font-size-h3;
  line-height: $line-height-h3;
  color: $text-color;
}

h4,
.h4 {
  font-family: $base-font;
  font-style: $font-style-base;
  font-weight: $font-weight-bold;
  font-size: $font-size-h4;
  line-height: $line-height-h4;
  color: $white;
}

p {
  font-family: $second-font;
  font-style: $font-style-base;
  font-weight: $font-weight-base;
  font-size: $font-size-p;
  line-height: $line-height-p;
  color: $text-color;
  margin: 0;

  @media only screen and (max-width: $screen-sm) {
    font-size: 18px;
    line-height: 24px;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
}

// Containers
//--------------------------
.containerCenter {
  @media only screen and (min-width: $higher-resolution) {
    margin: auto;
    max-width: 2000px;
  }
}

// Display property
//--------------------------
.d-none {
  display: none;
}
.d-md-block {
  @media only screen and (max-width: $screen-md) {
    display: block;
  }
}

// Tooltip
// -------------------------
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltipText {
  visibility: visible;
  width: 120px;
  background-color: $raisin-black;
  color: $white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 12px;
  font-weight: normal;

  /* Position the tooltip */
  position: absolute;
  top: 0;
  left: 30px;
  z-index: 1;
}

// Article cards
// -------------------------
.containerCards {
  @media screen and (min-width: $screen-sm) and (max-width: $screen-lg) {
    margin-bottom: 120px;
  }
  @media screen and (max-width: $screen-sm) {
    margin-bottom: 65px;
  }
}

.cards {
  -webkit-flex-wrap: wrap;
  -webkit-justify-content: space-between;
  flex-wrap: wrap;
  justify-content: space-between;
  display: -webkit-flex;
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;

  @media only screen and (max-width: $screen-lg) and (min-width: 819px) {
    margin-right: 8px;
    margin-left: 30px;
  }

  @media only screen and (max-width: 819px) and (min-width: 581px) {
    margin-top: 32px;
    margin-right: 8px;
    margin-left: 8px;
  }

  @media only screen and (max-width: 581px) {
    margin: 32px 16px 16px 16px;
  }

  &::after {
    content: '';

    @media only screen and (max-width: 1200px) and (min-width: 768px) {
      flex: 0 0 32%;
    }

    @media only screen and (max-width: 768px) and (min-width: 581px) {
      flex: 0 0 45%;
    }
  }
}

.cards-resposive {
  width: 331px;
  margin-right: 22px;
  margin-bottom: 29px;

  @media only screen and (max-width: 1200px) and (min-width: 819px) {
    flex: 0 0 30%;
  }

  @media only screen and (max-width: 819px) and (min-width: 581px) {
    flex: 0 0 45%;
    margin-right: 8px;
    margin-left: 8px;
  }

  @media only screen and (max-width: 581px) {
    flex: 0 0 100%;
    margin-right: 0;
    margin-bottom: 14px;
  }
}

.cards-resposive-smaller-card {
  margin-bottom: 34px;

  @media only screen and (min-width: $screen-lg) {
    height: 347px;
    max-width: 295px;
  }

  @media only screen and (max-width: $screen-md) {
    margin-bottom: 39px;
  }

  @media only screen and (max-width: $screen-xs) {
    height: unset;
  }
}

//
// CSS for blockquote
// -------------------------
blockquote {
  position: relative;
  margin: 0;
  padding: 30px 0;
  font-family: $base-font, sans-serif;
  font-size: 18px;
  font-style: italic;
  line-height: 26px;
  border-left: 0;
  transform: rotate(0deg);
}

blockquote p {
  font-family: $second-font, serif;
  font-size: 18px;
  font-style: italic;
  line-height: 26px;
  margin: 0 0 10px;
}

blockquote a {
  color: $link-color;
  text-decoration: none;
}

blockquote:before,
blockquote:after {
  width: 32px;
  height: 22px;
  background-size: 32px 22px;
}

blockquote:before,
blockquote:after {
  content: '';
  display: block;
  position: absolute;
}

blockquote:before,
blockquote:after {
  background: url(assets/images/quotation-marks.png) no-repeat 50% 50%;
}

blockquote:before {
  float: left;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
}

blockquote:after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  bottom: 0;
}

blockquote:after {
  transform: rotate(180deg);
}

//
// Link looks like a button
// -------------------------
a.link-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: $font-weight-500;
  font-size: 12px;
  line-height: 14px;
  padding: 11px 24px;
  background: $link-color;
  color: $white;
  border-radius: $button-border-radius;
  border: 0;

  &:after {
    content: '\e906';
    display: inline-block;
    margin-left: 7px;
    font-family: $icon-font;
    font-size: 12px;
    font-weight: 400;
    color: $white;
  }

  &:hover {
    background: $link-hover-color;
    cursor: pointer;
  }
}

a.link-primary.link-resize {
  @media only screen and (max-height: $resize-height-max) and (min-height: $resize-height-min) {
    font-size: 1.62vh;
    line-height: 1.89vh;
    padding: 1.49vh 3.24vh;

    &:after {
      margin-left: 0.75vh;
      font-size: 1.28vh;
    }
  }

  @media only screen and (max-height: 724px) and (min-width: $screen-lg) {
    font-size: 10px;
    line-height: 10px;
    padding: 11px 18px;
    &:after {
      font-size: 9px;
    }
  }
}
