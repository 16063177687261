// Fira Sans
@font-face {
  font-family: 'Fira Sans';
  src: url('../fonts/firaSans/FiraSans-Bold.woff2') format('woff2'),
    url('../fonts/firaSans/FiraSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../fonts/firaSans/FiraSans-SemiBold.woff2') format('woff2'),
    url('../fonts/firaSans/FiraSans-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../fonts/firaSans/FiraSans-Medium.woff2') format('woff2'),
    url('../fonts/firaSans/FiraSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Sans';
  src: url('../fonts/firaSans/FiraSans-Regular.woff2') format('woff2'),
    url('../fonts/firaSans/FiraSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// PT Serif
@font-face {
  font-family: 'PT Serif';
  src: url('../fonts/ptSerif/PTSerif-Bold.woff2') format('woff2'),
    url('../fonts/ptSerif/PTSerif-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'PT Serif';
  src: url('../fonts/ptSerif/PTSerif-Regular.woff2') format('woff2'),
    url('../fonts/ptSerif/PTSerif-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

// Helvetica Neue
@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/helvetica/Helvetica.woff') format('woff');
}
