@import 'src/assets/styles/variables';

.slideContent {
  text-align: center;

  h1 {
    margin-bottom: 26px;
    font-size: $font-size-h1;
    line-height: $line-height-h1;

    @media only screen and (max-width: $screen-sm), (max-height: 640px) {
      font-size: 23px;
      line-height: 31px;
    }

    @media only screen and (max-height: 400px) {
      margin-top: 8px;
      margin-bottom: 6px;
    }
  }

  img {

    @media only screen and (max-width: $screen-sm), (max-height: 640px) {
      width: 150px;
    }
  }

  p {
    max-width: 531px;
    margin: 17px auto 34px auto;
    color: $white;
    font-family: $base-font;
    font-size: 24px;
    line-height: 29px;
    text-align: left;

    @media only screen and (max-width: $screen-md) {
      padding-right: 40px;
      padding-left: 44px;
    }

    @media only screen and (max-width: $screen-sm), (max-height: 640px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  .slide__button button {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: $screen-xs) {
    display: flex;
    flex-flow: row wrap;
  }
}

.verticalCenter {
  width: 100%;
}
